import React from 'react'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { CallToAction, GetStarted } from '../../components/CallToAction'
import { Quote } from '../../components/Quote'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';

import SfOnLaptop from '../images/sfOnLaptop.png'
import SfNotes from '../images/sfNotes.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'
import OldWay from '../images/oldWay.svg';
import ActionEvent from '../images/actionEvent.svg';
import SalesforceDashboard from '../images/salesforceDashboard.png'
import ApiPuzzle from '../images/apiPuzzle.svg';
import ItMonitoring from '../images/itMonitoring.svg';


const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Better Salesforce Activity Tracking</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='headsetPerson2'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            Activity Tracking Without Compromise
          </PageTitle2>
          <PageTitle as="p" color={colors.white} mb="1.5rem" center>
            Finally, You Can Trust Your CRM Data
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>


    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={OldWay} alt="Sales adoption of sales tools"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          STEP 1: SOLVE SALES ADOPTION (ELIMINATE EXCUSES)
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
             Tracking activity takes away from valuable selling time and focus, which is why so many reps get away with not doing it.
           <br/><br/>
             To eliminate these (valid) excuses, you need to support every conceivable rep use case across devices (mobile, desktop, deskphone), workflows (cadencing, CRM, email inbox), and channels (calls, texts, email, web meetings) while delivering a delightful user experience.
          <br/><br/>
             Truly was built from the ground up to make activity tracking seamless, passive and delightful.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
          STEP 2: SYNC & CLEAN YOUR DATA
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
            Today's sales tools don't handle the hundreds of edge cases that cause your activity data to be bad.
           <br/><br/>
            Missing contacts, marketing emails, calendar events, group emails and duplicate records all create noise in your reporting.  Inconsistent logging behavior across different sales tools creates further noise every time your revenue team implements new software.
          <br/><br/>
            Truly's product suite is the only one to handle all of these edge cases, while also proactively cleansing your CRM data.
          </Large>
        </Box>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={ItMonitoring} alt="cleanse salesforce data hygiene"/>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={ApiPuzzle} alt="salesforce data enrichment"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          STEP 3: ENRICH YOUR DATA
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            You need a way to go beyond ambiguous metrics ("talk time") to be able to report on what is actually happening inside each activity ("seconds the rep actually spoke").
            <br/><br/>
             You also need to ensure all of your activities are tied to the right Accounts, Contacts and Opportunities to derive meaningful insights into your sales efforts.
            <br/><br/>
             Truly's leverages AI to transcribe analyze and extract meaning from all of your spoken and written communication, and pushes all of it to the right records in Salesforce. 

          </Large>
        </Box>
      </Section>
    </WrapperSolid>


    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
          STEP 4: NORMALIZE YOUR DATA
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
            Different tools sync to different fields and often have different meanings (eg: "talk time"), which makes historical analysis over longer periods difficult or meaningless.
            <br/><br/>
            Regardless of your data sources, you need to normalize your data across all providers to understand your funnel in one consistent way.
            <br/><br/>
            Truly's Salesforce native data pipelines make it easy to create a single reporting standard across all of your sales tools, while also enabling you to deliver reports to all of your teams in a single system of record.

          </Large>
        </Box>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={SalesforceDashboard} alt="Normalize Sales Data"/>
        </Box>
      </Section>
    </WrapperSolid>


    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={ActionEvent} alt="Automate Sales Process"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          STEP 5: AUTOMATE YOUR REVENUE PROCESSES
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Once you have all the data pushing to Salesforce in a continuous and structured way, you can automate virtually any sales or sales ops process.
            <br/><br/>
            Automatically update CRM fields based on customer conversations, score/flag deals at risk, notify reps/managers when a deal falls outsidee the process, and create follow up tasks for your reps with specific guidance on what to do next.
            <br/><br/>
            With Truly operating in the background, you can eliminate repetitive tasks while being confident that revenue isn't slipping through the cracks.

          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperBackground background="headsetPerson1" gradientType="purple">
      <CallToAction/>
    </WrapperBackground>

    <WrapperBackground background="sf" gradientType="black">
      <GetStarted/>
    </WrapperBackground>

  </Default>
)

export default SolutionsPage

